var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"parent",attrs:{"id":"layout"}},[_c('div',{class:_vm.showFullMenu === true ? 'side-nav' : 'only-icons-nav'},[_c('div',{staticClass:"search-bar"},[(!_vm.showFullMenu)?_c('div',{staticClass:"search-icon"},[_c('mds-button',{attrs:{"variation":"icon-only","icon":"search","type":"button","title":"Search"},on:{"click":function($event){return _vm.onSearchButtonClick()}}},[_vm._v(" Search ")])],1):_vm._e(),(_vm.showFullMenu)?_c('div',[_c('mds-search-field',{ref:"searchField",staticClass:"search-field",attrs:{"placeholder":"Search...","variation":"input","label":"Search","size":"touch"},on:{"keyup":_vm.debounceInput,"mds-search-field-input-cleared":function($event){return _vm.searchFieldCleared()}},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}}),(_vm.searchResult)?_c('mds-search-results',{key:_vm.searchResultKey,staticClass:"search-result",attrs:{"id":"search-result","width":"300px"}},[_vm._l((_vm.searchSamples),function(searchSample,index){return _c('mds-search-results-section',{key:index,staticClass:"section-result-header",attrs:{"title":searchSample.type},scopedSlots:_vm._u([{key:"mds-search-results-section-actions",fn:function(){return [_c('span',{staticClass:"search-result-count",on:{"click":function($event){return _vm.clearSearchFiledAndRoute(
                                        searchSample.id,
                                        _vm.searchInput
                                    )}}},[_vm._v(" "+_vm._s(searchSample.count > 3 ? searchSample.count - 3 : 0)+" more")])]},proxy:true}],null,true)},[_c('mds-list-group',{attrs:{"size":"small","variation":"navigation"}},_vm._l((searchSample.result),function(Result){return _c('mds-list-group-item',{key:Result.id},[[_c('span',{domProps:{"innerHTML":_vm._s(Result.name)},on:{"click":function($event){return _vm.clearSearchFiledAndRouteToAddInvestment(
                                                searchSample.actiondetails,
                                                Result.id
                                            )}}})]],2)}),1)],1)}),(_vm.searchResultNull)?_c('mds-search-results-section',{attrs:{"title":"No Results"}}):_vm._e()],2):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"menu-items-list"},[_c('mds-list-group',{attrs:{"with-icon":""}},_vm._l((_vm.menuItems),function(item,index){return _c('mds-list-group-item',{key:item.id,staticClass:"menu-item",class:[
                        item.hasActiveSelection === true
                            ? 'item-highlight'
                            : '' ],style:(_vm.menuItems.length - 1 === index
                            ? 'margin-bottom: 5px'
                            : ''),attrs:{"title":_vm.showFullMenu === false ? item.containerText : ''},on:{"mds-list-item-clicked":function($event){return _vm.navigateByMainItems(item.navigationPath, item.id)}},scopedSlots:_vm._u([(item.canExpand === true && item.toggleParentMenu)?{key:"mds-list-item-right",fn:function(){return [_c('span',{class:[
                                _vm.showFullMenu === false ? 'only-icons' : '',
                                'menu-item-side-icon' ]},[_c('mds-icon',{class:item.hasActiveSelection === true
                                        ? 'highlight-side-icon'
                                        : '',attrs:{"name":item.sideIcon,"size":"small"}})],1)]},proxy:true}:null],null,true)},[(
                            item.icon !== 'dissemination' &&
                            item.icon !== 'tpa' &&
                            item.icon !== 'fog' &&
                            item.toggleParentMenu
                        )?_c('mds-icon',{class:[
                            item.hasActiveSelection === true &&
                            _vm.showFullMenu === true
                                ? 'menu-left-icon-highlight'
                                : '',
                            'menu-icons' ],attrs:{"name":item.icon,"size":"medium"}}):_vm._e(),(item.icon === 'dissemination' && item.toggleParentMenu)?_c('svg',{staticClass:"menu-icons svg-menu-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 15 15","alt":"data dissemination icon"}},[_c('path',{attrs:{"fill":"none","stroke":item.hasActiveSelection === true &&
                                _vm.showFullMenu === true
                                    ? '#0077cf'
                                    : '#5e5e5e',"stroke-miterlimit":"10","d":"M12.356.815l-6.05 6.05","vector-effect":"non-scaling-stroke"}}),_c('path',{attrs:{"fill":"none","stroke":item.hasActiveSelection === true &&
                                _vm.showFullMenu === true
                                    ? '#0077cf'
                                    : '#5e5e5e',"stroke-linecap":"square","stroke-miterlimit":"10","d":"M12.356.815l-3.85 11-2.2-4.95-4.95-2.2 11-3.85z","vector-effect":"non-scaling-stroke"}})]):_vm._e(),(item.icon === 'fog' && item.toggleParentMenu)?_c('svg',{staticClass:"menu-icons svg-menu-icon",attrs:{"focusable":"false","viewBox":"0 0 15 15","fill":"none","xmlns":"http://www.w3.org/2000/svg","alt":"tpa audit icon"}},[_c('path',{attrs:{"stroke":item.hasActiveSelection === true &&
                                _vm.showFullMenu === true
                                    ? '#0077cf'
                                    : '#5E5E5E',"d":"M1.5 3.5h12v8.75h-12zM4.75 3.15v9M4.50 7.917H13.5","vector-effect":"non-scaling-stroke"}})]):_vm._e(),(item.icon === 'tpa' && item.toggleParentMenu)?_c('svg',{staticClass:"menu-icons svg-menu-icon",attrs:{"focusable":"false","viewBox":"0 0 15 15","fill":"none","xmlns":"http://www.w3.org/2000/svg","alt":"tpa audit icon"}},[_c('path',{attrs:{"stroke":item.hasActiveSelection === true &&
                                _vm.showFullMenu === true
                                    ? '#0077cf'
                                    : '#5E5E5E',"d":"M2.91 7.441V2.357a4.718 4.718 0 002.195.54A6.202 6.202 0 007.5 1.782a6.202 6.202 0 002.395 1.113 4.718 4.718 0 002.195-.54v5.085c0 3.286-2.36 4.739-4.59 5.776-2.23-1.037-4.59-2.49-4.59-5.776zm2.649-.065l1.559 1.559 2.336-3.376","vector-effect":"non-scaling-stroke","stroke-miterlimit":"10"}})]):_vm._e(),(item.toggleParentMenu)?_c('span',{class:[
                            _vm.showFullMenu === false ? 'only-icons' : '',
                            'menu-item-text' ]},[_vm._v(_vm._s(item.containerText))]):_vm._e(),(item.isExpanded === true)?_c('mds-list-group',{class:[
                            _vm.showFullMenu === false ? 'only-icons' : '',
                            item.hasActiveSelection === true
                                ? 'menu-sub-items-group'
                                : '' ]},_vm._l((item.subItems),function(subItem,index){return _c('li',{key:subItem.id,staticClass:"layout-sub-list",class:[
                                subItem.isActive === true
                                    ? 'sub-item-highlight'
                                    : '',
                                'menu-item',
                                'menu-sub-item' ],style:(index === 0 ? 'margin-top: 1px' : ''),on:{"click":function($event){$event.stopPropagation();return _vm.navigateBySubItems(
                                    subItem.navigationPath,
                                    false
                                )}}},[(subItem.toggleMenu)?_c('span',[_c('router-link',{class:[
                                subItem.isActive === true
                                    ? 'side_menu_sub_heading-highlight'
                                    : 'side_menu_sub_heading' ],attrs:{"to":subItem.navigationPath}},[_vm._v(_vm._s(subItem.containerText))])],1):_vm._e()])}),0):_vm._e()],1)}),1),_c('div',{class:[
                    _vm.showFullMenu === false
                        ? 'hide-menu-item-with-hidden-menu'
                        : 'hide-menu-item' ],on:{"click":function($event){return _vm.showMenu()}}},[_c('div',{staticClass:"hide-menu-icon"},[_c('mds-button',{attrs:{"variation":"icon-only","icon":_vm.hideMenuIcon,"type":"button","title":_vm.showFullMenu === true ? '' : 'Expand Menu'}})],1),_c('div',{class:[
                        _vm.showFullMenu === false ? 'only-icons' : '',
                        'hide-menu-text' ]},[_vm._v(" Hide Menu ")])])],1)]),_c('div',{class:[
            _vm.showFullMenu === true
                ? 'main-screen'
                : 'main-screen-with-hidden-menu' ]},[_c('div',{class:[
                _vm.showFullMenu === true
                    ? 'top-bar'
                    : 'top-bar-with-hidden-menu' ]},[_c('div',{staticClass:"top-bar-container"},[_vm._m(0),_c('div',{staticClass:"action-icons"},[_c('mds-button',{staticClass:"icon-buttons",attrs:{"variation":"icon-only","icon":"bell","type":"button"}},[_vm._v(" bell ")]),_c('mds-button',{staticClass:"icon-buttons",attrs:{"variation":"icon-only","icon":"person","type":"button","id":"custom-position-trigger"},on:{"click":function($event){_vm.toggle = !_vm.toggle}}},[_vm._v(" User ")]),_c('mds-popover',{attrs:{"width":"300px","position":"bottom-left","title-hidden":"","triggered-by":"custom-position-trigger"},model:{value:(_vm.toggle),callback:function ($$v) {_vm.toggle=$$v},expression:"toggle"}},[_c('mds-list-group',{staticClass:"list"},[_c('mds-list-group-item',{staticClass:"signout-listItem"},[_vm._v(" "+_vm._s(_vm.userName)+" ")]),_c('mds-list-group-item',{staticClass:"signout-listItem",on:{"mds-list-item-clicked":_vm.logout}},[_vm._v("Sign out")])],1)],1)],1)])]),_c('div',{staticClass:"content-bar"},[_c('router-view',{key:_vm.$route.path,attrs:{"menuwidth":_vm.menuWidth,"passEntityDetailsObject":_vm.passEntityDetailsObject,"passAddInvestmentDetailsObject":_vm.passAddInvestmentDetailsObject}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand-logo-container"},[_c('img',{staticClass:"brand-logo",attrs:{"src":require("../assets/Images/Mstar-logo-50px.svg"),"alt":"Product Name"}})])}]

export { render, staticRenderFns }