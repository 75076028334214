<template>
    <div class="parent" id="layout">
        <div :class="showFullMenu === true ? 'side-nav' : 'only-icons-nav'">
            <!-- search bar functionality -->
            <div class="search-bar">
                <div class="search-icon" v-if="!showFullMenu">
                    <mds-button
                        variation="icon-only"
                        icon="search"
                        type="button"
                        title="Search"
                        @click="onSearchButtonClick()"
                    >
                        Search
                    </mds-button>
                </div>
                <div v-if="showFullMenu">
                    <!-- search-panel -->
                    <mds-search-field
                        ref="searchField"
                        class="search-field"
                        placeholder="Search..."
                        variation="input"
                        label="Search"
                        size="touch"
                        v-model="searchInput"
                        @keyup="debounceInput"
                        @mds-search-field-input-cleared="searchFieldCleared()"
                    ></mds-search-field>

                    <mds-search-results
                        id="search-result"
                        width="300px"
                        class="search-result"
                        v-if="searchResult"
                        :key="searchResultKey"
                    >
                        <mds-search-results-section
                            class="section-result-header"
                            v-for="(searchSample, index) in searchSamples"
                            :key="index"
                            :title="searchSample.type"
                        >
                            <template v-slot:mds-search-results-section-actions>
                                <span
                                    class="search-result-count"
                                    @click="
                                        clearSearchFiledAndRoute(
                                            searchSample.id,
                                            searchInput
                                        )
                                    "
                                >
                                    {{
                                        searchSample.count > 3
                                            ? searchSample.count - 3
                                            : 0
                                    }}
                                    more</span
                                >
                            </template>
                            <mds-list-group size="small" variation="navigation">
                                <mds-list-group-item
                                    v-for="Result in searchSample.result"
                                    :key="Result.id"
                                >
                                    <template>
                                        <span
                                            v-html="Result.name"
                                            @click="
                                                clearSearchFiledAndRouteToAddInvestment(
                                                    searchSample.actiondetails,
                                                    Result.id
                                                )
                                            "
                                        ></span>
                                    </template>
                                </mds-list-group-item>
                            </mds-list-group>
                        </mds-search-results-section>

                        <!-- If output is NULL, then show this search-result -->
                        <mds-search-results-section
                            v-if="searchResultNull"
                            title="No Results"
                        >
                        </mds-search-results-section>
                    </mds-search-results>
                </div>
            </div>

            <!-- menu-items list -->
            <div class="menu-items-list">
                <mds-list-group with-icon>
                    <mds-list-group-item
                        class="menu-item"
                        v-for="(item, index) in menuItems"
                        :key="item.id"
                        :class="[
                            item.hasActiveSelection === true
                                ? 'item-highlight'
                                : '',
                        ]"
                        @mds-list-item-clicked="
                            navigateByMainItems(item.navigationPath, item.id)
                        "
                        :style="
                            menuItems.length - 1 === index
                                ? 'margin-bottom: 5px'
                                : ''
                        "
                        :title="showFullMenu === false ? item.containerText : ''"
                    >
                        <!-- to display menu icons except data dissemination & tpa audit -->
                        <mds-icon
                            v-if="
                                item.icon !== 'dissemination' &&
                                item.icon !== 'tpa' &&
                                item.icon !== 'fog' &&
                                item.toggleParentMenu
                            "
                            :class="[
                                item.hasActiveSelection === true &&
                                showFullMenu === true
                                    ? 'menu-left-icon-highlight'
                                    : '',
                                'menu-icons',
                            ]"
                            :name="item.icon"
                            size="medium"
                        ></mds-icon>

                        <!-- data dissemination icon -->
                        <svg
                            class="menu-icons svg-menu-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 15 15"
                            v-if="item.icon === 'dissemination' && item.toggleParentMenu"
                            alt="data dissemination icon"
                        >
                            <path
                                fill="none"
                                :stroke="
                                    item.hasActiveSelection === true &&
                                    showFullMenu === true
                                        ? '#0077cf'
                                        : '#5e5e5e'
                                "
                                stroke-miterlimit="10"
                                d="M12.356.815l-6.05 6.05"
                                vector-effect="non-scaling-stroke"
                            />
                            <path
                                fill="none"
                                :stroke="
                                    item.hasActiveSelection === true &&
                                    showFullMenu === true
                                        ? '#0077cf'
                                        : '#5e5e5e'
                                "
                                stroke-linecap="square"
                                stroke-miterlimit="10"
                                d="M12.356.815l-3.85 11-2.2-4.95-4.95-2.2 11-3.85z"
                                vector-effect="non-scaling-stroke"
                            />
                        </svg>

                        <!-- fog icon -->
                        <svg
                            class="menu-icons svg-menu-icon"
                            focusable="false"
                            viewBox="0 0 15 15"
                            v-if="item.icon === 'fog' && item.toggleParentMenu"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            alt="tpa audit icon"
                        >
                            <path
                                :stroke="
                                    item.hasActiveSelection === true &&
                                    showFullMenu === true
                                        ? '#0077cf'
                                        : '#5E5E5E'
                                "
                                d="M1.5 3.5h12v8.75h-12zM4.75 3.15v9M4.50 7.917H13.5"
                                vector-effect="non-scaling-stroke"
                            />
                        </svg>

                        <!-- tpa audit icon -->
                        <svg
                            class="menu-icons svg-menu-icon"
                            focusable="false"
                            viewBox="0 0 15 15"
                            v-if="item.icon === 'tpa' && item.toggleParentMenu"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            alt="tpa audit icon"
                        >
                            <path
                                :stroke="
                                    item.hasActiveSelection === true &&
                                    showFullMenu === true
                                        ? '#0077cf'
                                        : '#5E5E5E'
                                "
                                d="M2.91 7.441V2.357a4.718 4.718 0 002.195.54A6.202 6.202 0 007.5 1.782a6.202 6.202 0 002.395 1.113 4.718 4.718 0 002.195-.54v5.085c0 3.286-2.36 4.739-4.59 5.776-2.23-1.037-4.59-2.49-4.59-5.776zm2.649-.065l1.559 1.559 2.336-3.376"
                                vector-effect="non-scaling-stroke"
                                stroke-miterlimit="10"
                            />
                        </svg>

                        <span
                            v-if="item.toggleParentMenu"
                            :class="[
                                showFullMenu === false ? 'only-icons' : '',
                                'menu-item-text',
                            ]"
                            >{{ item.containerText }}</span
                        >

                        <template
                            v-if="item.canExpand === true && item.toggleParentMenu"
                            v-slot:mds-list-item-right
                        >
                            <!-- side-icon (angle-down/angle-up) -->
                            <span
                                :class="[
                                    showFullMenu === false ? 'only-icons' : '',
                                    'menu-item-side-icon',
                                ]"
                            >
                                <mds-icon
                                    :class="
                                        item.hasActiveSelection === true
                                            ? 'highlight-side-icon'
                                            : ''
                                    "
                                    :name="item.sideIcon"
                                    size="small"
                                ></mds-icon>
                            </span>
                        </template>

                        <!-- menu sub-items list -->
                        <mds-list-group
                            :class="[
                                showFullMenu === false ? 'only-icons' : '',
                                item.hasActiveSelection === true
                                    ? 'menu-sub-items-group'
                                    : '',
                            ]"
                            v-if="item.isExpanded === true"
                        >
                            <li
                                class="layout-sub-list"
                                v-for="(subItem, index) in item.subItems"
                                :key="subItem.id"
                                :class="[
                                    subItem.isActive === true
                                        ? 'sub-item-highlight'
                                        : '',
                                    'menu-item',
                                    'menu-sub-item',
                                ]"
                                @click.stop="
                                    navigateBySubItems(
                                        subItem.navigationPath,
                                        false
                                    )
                                "
                                :style="index === 0 ? 'margin-top: 1px' : ''"
                            >
                                <span v-if="subItem.toggleMenu">
                                    <router-link :to="subItem.navigationPath" :class="[
                                    subItem.isActive === true
                                        ? 'side_menu_sub_heading-highlight'
                                        : 'side_menu_sub_heading',
                                ]">{{ subItem.containerText }}</router-link>
                                </span>
                            </li>
                        </mds-list-group>
                        
                    </mds-list-group-item>
                </mds-list-group>

                <div
                    :class="[
                        showFullMenu === false
                            ? 'hide-menu-item-with-hidden-menu'
                            : 'hide-menu-item',
                    ]"
                    @click="showMenu()"
                >
                    <div class="hide-menu-icon">
                        <mds-button
                            variation="icon-only"
                            :icon="hideMenuIcon"
                            type="button"
                            :title="showFullMenu === true ? '' : 'Expand Menu' "
                        >
                        </mds-button>
                    </div>
                    <div
                        :class="[
                            showFullMenu === false ? 'only-icons' : '',
                            'hide-menu-text',
                        ]"
                    >
                        Hide Menu
                    </div>
                </div>
            </div>
        </div>

        <!-- main-screen (right block) -->
        <div
            :class="[
                showFullMenu === true
                    ? 'main-screen'
                    : 'main-screen-with-hidden-menu',
            ]"
        >
            <!-- top header & user actions bar -->
            <div
                :class="[
                    showFullMenu === true
                        ? 'top-bar'
                        : 'top-bar-with-hidden-menu',
                ]"
            >
                <div class="top-bar-container">
                    <!-- Morningstar brand logo container -->
                    <div class="brand-logo-container">
                        <img
                            class="brand-logo"
                            src="../assets/Images/Mstar-logo-50px.svg"
                            alt="Product Name"
                        />
                    </div>
                    <!-- signout & notifications(bell) icons container -->
                    <div class="action-icons">
                        <mds-button
                            class="icon-buttons"
                            variation="icon-only"
                            icon="bell"
                            type="button"
                        >
                            bell
                        </mds-button>
                        <mds-button
                            class="icon-buttons"
                            variation="icon-only"
                            icon="person"
                            type="button"
                            @click="toggle = !toggle"
                            id="custom-position-trigger"
                        >
                            User
                        </mds-button>
                        <!-- signed in user info & signout popover -->
                        <mds-popover
                            width="300px"
                            position="bottom-left"
                            title-hidden
                            triggered-by="custom-position-trigger"
                            v-model="toggle"
                        >
                            <mds-list-group class="list">
                                <mds-list-group-item class="signout-listItem">
                                    {{ userName }}
                                </mds-list-group-item>
                                <mds-list-group-item
                                    class="signout-listItem"
                                    v-on:mds-list-item-clicked="logout"
                                    >Sign out</mds-list-group-item
                                >
                            </mds-list-group>
                        </mds-popover>
                    </div>
                </div>
            </div>

            <!-- component screen container -->
            <div class="content-bar">
                <router-view
                    :menuwidth="menuWidth"
                    :key="$route.path"
                    :passEntityDetailsObject="passEntityDetailsObject"
                    :passAddInvestmentDetailsObject="
                        passAddInvestmentDetailsObject
                    "
                />
            </div>
        </div>
    </div>
</template>

<script>
import debounce from "lodash/debounce";
import MdsPopover from "@mds/popover";
import { MdsButton } from "@mds/button";
import { MdsListGroup, MdsListGroupItem } from "@mds/list-group";
import { MdsSearchResults, MdsSearchResultsSection } from "@mds/search-results";
import MdsSearchField from "@mds/search-field";
// import { mapActions, mapGetters } from "vuex";
import MdsIcon from "@mds/icon";
import { searchEntities } from "../services/layout.service.js";

export default {
  components: {
    MdsPopover,
    MdsButton,
    MdsSearchResults,
    MdsSearchResultsSection,
    MdsListGroup,
    MdsListGroupItem,
    MdsSearchField,
    MdsIcon,
  },
  data() {
    return {
      userName: "",
      toggle: false,
      showFullMenu: true,
      hideMenu: false,
      hideMenuIcon: "angle-double-left",

      // search-panel
      searchResult: false,
      searchInput: "",
      searchSamples: [],
      searchResultKey: 0,
      selectedEntityType: null,
      entityid: null,
      searchResultNull: false,
      getSearchEntities: [],

      menuItems: [
        {
          id: 0,
          canExpand: false,
          containerText: "Home",
          hasActiveSelection: false,
          icon: "home",
          isExpanded: false,
          navigationPath: "/home",
          toggleParentMenu: true,
          subItems: [],
        },
        {
          id: 1,
          canExpand: true,
          containerText: "Tasks",
          hasActiveSelection: false,
          icon: "list",
          isExpanded: false,
          navigationPath: "",
          sideIcon: "angle-down",
          toggleParentMenu: this.showTaskMenu(),
          subItems: [
            {
              id: 1,
              containerText: "Assigned to You",
              navigationPath: "/tasks/assigned-to-you",
              isActive: false,
              toggleMenu: this.$store.state.auth.permissions.includes('/tasks/assigned-to-you') ||
              this.$store.state.auth.permissions.includes('*')
            },
            {
              id: 2,
              containerText: "Completed",
              navigationPath: "/tasks/completed",
              isActive: false,
              toggleMenu: this.$store.state.auth.permissions.includes('/tasks/completed') ||
              this.$store.state.auth.permissions.includes('*')
            },
          ],
        },
        {
          id: 2,
          canExpand: true,
          containerText: "Data Management",
          hasActiveSelection: false,
          icon: "file-cabinet",
          isExpanded: false,
          navigationPath: "",
          sideIcon: "angle-down",
          toggleParentMenu: this.showDataManagement(),
          subItems: [
            {
              id: 1,
              containerText: "Add Investment",
              navigationPath: "/data-management/add-investment",
              isActive: false,
              toggleMenu: this.$store.state.auth.permissions.includes('/data-management/add-investment') ||
              this.$store.state.auth.permissions.includes('*')
            },
            {
              id: 2,
              containerText: "Entities",
              navigationPath: "/data-management/entities",
              isActive: false,
              toggleMenu: this.$store.state.auth.permissions.includes('/data-management/entities') ||
              this.$store.state.auth.permissions.includes('*')
            },
            {
              id: 3,
              containerText: "Import Data",
              navigationPath: "/data-management/import-data",
              isActive: false,
              toggleMenu: this.$store.state.auth.permissions.includes('/data-management/import-data') ||
              this.$store.state.auth.permissions.includes('*')
            },
          ],
        },
        {
          id: 3,
          canExpand: true,
          containerText: "Data Dissemination",
          hasActiveSelection: false,
          icon: "dissemination",
          isExpanded: false,
          navigationPath: "",
          sideIcon: "angle-down",
          toggleParentMenu: this.showDissemination(),
          subItems: [
            {
              id: 1,
              containerText: "Data Feeds",
              navigationPath: "/data-dissemination/data-feeds",
              isActive: false,
              toggleMenu: this.$store.state.auth.permissions.includes('/data-dissemination/data-feeds') ||
              this.$store.state.auth.permissions.includes('*')
            },
            {
              id: 2,
              containerText: "Partners",
              navigationPath: "/data-dissemination/partners",
              isActive: false,
              toggleMenu: this.$store.state.auth.permissions.includes('/data-dissemination/partners') ||
              this.$store.state.auth.permissions.includes('*')
            },
            {
              id: 3,
              containerText: "Schedules",
              navigationPath: "/data-dissemination/schedules",
              isActive: false,
              toggleMenu: this.$store.state.auth.permissions.includes('/data-dissemination/schedules') ||
              this.$store.state.auth.permissions.includes('*')
            },
          ],
        },
        {
          id: 4,
          canExpand: true,
          containerText: "Reporting",
          icon: "document",
          isExpanded: false,
          hasActiveSelection: false,
          navigationPath: "",
          sideIcon: "angle-down",
          toggleParentMenu: this.showReporting(),
          subItems: [
            {
              id: 1,
              containerText: "Reports",
              navigationPath: "/reporting/reports",
              isActive: false,
              toggleMenu: this.$store.state.auth.permissions.includes('/reporting/reports') ||
              this.$store.state.auth.permissions.includes('*')
            },
            
          ],
        },
        {
          id: 5,
          canExpand: true,
          containerText: "Audit",
          icon: "tpa",
          isExpanded: false,
          hasActiveSelection: false,
          navigationPath: "",
          sideIcon: "angle-down",
          toggleParentMenu: this.showAudit(),
          subItems: [
            { 
              id: 1,
              containerText: "Activity History",
              navigationPath: "/audit/activity-history",
              isActive: false,
              toggleMenu: this.$store.state.auth.permissions.includes('/audit/activity-history') ||
              this.$store.state.auth.permissions.includes('*')
            },
          ],
        },
        //  {
        //   id: 6,
        //   canExpand: true,
        //   containerText: "FOG",
        //   icon: "fog",
        //   isExpanded: false,
        //   hasActiveSelection: false,
        //   navigationPath: "",
        //   sideIcon: "angle-down",
        //   toggleParentMenu: this.showFogMenu(),
        //   subItems: [
        //     {
        //       id: 1,
        //       containerText: "Admin",
        //       navigationPath: "/fog/admin",
        //       isActive: false,
        //       toggleMenu: this.$store.state.auth.permissions.includes('/fog/admin') ||
        //       this.$store.state.auth.permissions.includes('*')
        //     },
        //     {
        //       id: 2,
        //       containerText: "Bulk Edit",
        //       navigationPath: "/fog/bulk-edit",
        //       isActive: false,
        //       toggleMenu: this.$store.state.auth.permissions.includes('/fog/bulk-edit') ||
        //       this.$store.state.auth.permissions.includes('*')
        //     },
        //     {
        //       id: 3,
        //       containerText: "Data",
        //       navigationPath: "/fog/data",
        //       isActive: false,
        //       toggleMenu: this.$store.state.auth.permissions.includes('/fog/data') ||
        //       this.$store.state.auth.permissions.includes('*')
        //     },
        //     {
        //       id: 4,
        //       containerText: "Reporting",
        //       navigationPath: "/fog/reporting",
        //       isActive: false,
        //       toggleMenu: this.$store.state.auth.permissions.includes('/fog/reporting') ||
        //       this.$store.state.auth.permissions.includes('*')
        //     },
        //   ],
        // },
        {
          id: 6,
          canExpand: true,
          containerText: "Settings",
          hasActiveSelection: false,
          icon: "gear",
          isExpanded: false,
          navigationPath: "",
          sideIcon: "angle-down",
          toggleParentMenu: this.showSettings(),
          subItems: [
            {
              id: 1,
              containerText: "Alerts",
              navigationPath: "/settings/alerts",
              isActive: false,
              toggleMenu: this.$store.state.auth.permissions.includes('/settings/alerts') ||
              this.$store.state.auth.permissions.includes('*')
            },
            {
              id: 2,
              containerText: "Data Model",
              navigationPath: "/settings/data-model",
              isActive: false,
              toggleMenu: this.$store.state.auth.permissions.includes('/settings/data-model') ||
              this.$store.state.auth.permissions.includes('*')
            },
            {
              id: 3,
              containerText: "Data Sets",
              navigationPath: "/settings/data-sets",
              isActive: false,
              toggleMenu: this.$store.state.auth.permissions.includes('/settings/data-sets') ||
              this.$store.state.auth.permissions.includes('*')
            },
            {
              id: 4,
              containerText: "Document Management",
              navigationPath: "/settings/document-management",
              isActive: false,
              toggleMenu: this.$store.state.auth.permissions.includes('/settings/document-management') ||
              this.$store.state.auth.permissions.includes('*')
            },
            {
              id: 5,
              containerText: "Templates",
              navigationPath: "/settings/templates",
              isActive: false,
              toggleMenu: this.$store.state.auth.permissions.includes('/settings/templates') ||
              this.$store.state.auth.permissions.includes('*')
            },
            {
              id: 6,
              containerText: "Universes",
              navigationPath: "/settings/universes",
              isActive: false,
              toggleMenu: this.$store.state.auth.permissions.includes('/settings/universes') ||
              this.$store.state.auth.permissions.includes('*')
            },
            {
              id: 7,
              containerText: "User Management",
              navigationPath: "/settings/user-management",
              isActive: false,
              toggleMenu: this.$store.state.auth.permissions.includes('/settings/user-management') ||
              this.$store.state.auth.permissions.includes('*')
            },
            {
              id: 8,
              containerText: "Workflows",
              navigationPath: "/",
              isActive: false,
              toggleMenu: this.$store.state.auth.permissions.includes('/settings/workflow') ||
              this.$store.state.auth.permissions.includes('*')
            },
          ],
        },
        {
          id: 7,
          canExpand: true,
          containerText: "Help",
          icon: "question-circle",
          isExpanded: false,
          hasActiveSelection: false,
          navigationPath: "",
          sideIcon: "angle-down",
          toggleParentMenu: this.showHelpMenu(),
          subItems: [
            {
              id: 1,
              containerText: "Data Dictionary",
              navigationPath: "/",
              isActive: false,
              toggleMenu: this.$store.state.auth.permissions.includes('/help/data-dictionary') ||
              this.$store.state.auth.permissions.includes('*')
            },
            {
              id: 2,
              containerText: "Get Support",
              navigationPath: "/",
              isActive: false,
              toggleMenu: this.$store.state.auth.permissions.includes('/help/support') ||
              this.$store.state.auth.permissions.includes('*')
            },
          ],
        },
      ],
      passEntityDetailsObject: {},
      passAddInvestmentDetailsObject: {},
    };
  },

  created() {
    // to filter out all the unwanted menu items.
    this.menuItems = this.menuItems.filter((item) => item.toggleParentMenu === true);
    this.menuItems.forEach((item, index) => item.id = index);
  },

  mounted() {
    if (sessionStorage.getItem("permissions")) {
      let permissions = JSON.parse(sessionStorage.getItem("permissions"));
      this.userName = permissions.userEmail;
    }
    // const getAllPermissions = this.$store.state.auth.permissions;
    this.navigateBySubItems(this.$route.path, true);
    // search-panel
    window.addEventListener("click", this.searchFieldCleared);

    if (this.$route.path === "/home") {
      this.navigateByMainItems(this.$route.path, 0);
    } 
    else{
        // suppose any user direct write parent or child url then this event will expand parent and highlight child component
        this.commonNavigationFunction(this.$route.path)
    }
  },

    watch: {
        $route({ path }) {
            if (path !== "/home") {
               this.commonNavigationFunction(path) 
            } else {
                this.navigateByMainItems(path, 0);
            }
        },
    },
    computed: {
        // search-panel
        // ...mapGetters("layout", ["getSearchEntities"]),
        menuWidth() {
            if (this.showFullMenu === true) {
                return "214px";
            } else {
                return "60px";
            }
        },
    },
    methods: {

        // event for navigation from parent to child and vice-versa
        commonNavigationFunction(path){
            // this condition is written separately because we have child paths as well
               
                // tasks
                if (path.toString().includes("/tasks/assigned-to-you")) {
                    this.navigateBySubItems("/tasks/assigned-to-you", false, true);
                } else if (path.toString().includes("/tasks/completed")) {
                    this.navigateBySubItems("/tasks/completed", false, true);
                } else if (path.toString().includes("/approve")) {
                    this.navigateBySubItems("/tasks/assigned-to-you", false, true);
                }

                // data management
                else if (path.toString().includes("/data-management/add-investment")) {
                    this.navigateBySubItems("/data-management/add-investment", false, true);
                }else if (path.toString().includes("/data-management/entities")) {
                    this.navigateBySubItems("/data-management/entities", false, true);
                } else if (path.toString().includes("/data-management/import-data")) {
                    this.navigateBySubItems("/data-management/import-data", false, true);
                }

                // data dissemination
                else if (path.toString().includes("/data-dissemination/schedules")) {
                    this.navigateBySubItems("/data-dissemination/schedules", false, true);
                } else if (path.toString().includes("/data-dissemination/partners/create")) {
                    this.navigateBySubItems("/data-dissemination/partners", false, true);
                } else if (path.toString().includes("/data-dissemination/data-feeds/create")) {
                    this.navigateBySubItems("/data-dissemination/data-feeds", false, true);
                }

                // reporting
                else if (path.toString().includes("/reporting/reports/run-new-report")) {
                    this.navigateBySubItems("/reporting/reports", false, true);
                }

                //audit
                else if (path.toString().includes("/audit/activity-history")) {
                    this.navigateBySubItems("/audit/activity-history", false, true);
                }
                // FOG
                // else if (path.toString().includes("/fog/admin")) {
                //     this.navigateBySubItems("/fog/admin", false, true);
                // } else if (path.toString().includes("/fog/bulk-edit")) {
                //     this.navigateBySubItems("/fog/bulk-edit", false, true);
                // } else if (path.toString().includes("/fog/data")) {
                //     this.navigateBySubItems("/fog/data", false, true);
                // } else if (path.toString().includes("/fog/reporting")) {
                //     this.navigateBySubItems("/fog/reporting", false, true);
                // } 

                //settings
                else if (path.toString().includes("/settings/alerts/create")) {
                    this.navigateBySubItems("/settings/alerts", false, true);
                } else if (path.toString().includes("/settings/data-model")) {
                    this.navigateBySubItems("/settings/data-model", false, true);
                } else if (path.toString().includes("/settings/data-sets")) {
                    this.navigateBySubItems("/settings/data-sets", false, true);
                } else if (path.toString().includes("/settings/document-management")) {
                    this.navigateBySubItems("/settings/document-management", false, true);
                } else if (path.toString().includes("/settings/universes")) {
                    this.navigateBySubItems("/settings/universes", false, true);
                } else if (path.toString().includes("/settings/templates")) {
                    this.navigateBySubItems("/settings/templates", false, true);
                } else if (path.toString().includes("/settings/user-management/add-user")) {
                    this.navigateBySubItems("/settings/user-management", false, true);
                }
                
                // home
                else {
                    // third argument is added to let method know that back button of browser is clicked
                    this.navigateBySubItems(path, false, true);
                }
        },


        showTaskMenu() {
            return this.$store.state.auth.permissions.includes('/tasks/assigned-to-you')
            || this.$store.state.auth.permissions.includes('/tasks/completed')
            || this.$store.state.auth.permissions.includes('*');
        },
        // showFogMenu() {
        //     return this.$store.state.auth.permissions.includes('/fog/admin')
        //     || this.$store.state.auth.permissions.includes('/fog/bulk-edit')
        //     || this.$store.state.auth.permissions.includes('/fog/data')
        //     || this.$store.state.auth.permissions.includes('/fog/reporting')
        //     || this.$store.state.auth.permissions.includes('*');
        // },
        showDataManagement() {
            return this.$store.state.auth.permissions.includes('/data-management/add-investment')
            || this.$store.state.auth.permissions.includes('/data-management/entities')
            || this.$store.state.auth.permissions.includes('/data-management/import-data')
            || this.$store.state.auth.permissions.includes('*');
        },
        showDissemination() {
            return this.$store.state.auth.permissions.includes('/data-dissemination/data-feeds')
            || this.$store.state.auth.permissions.includes('/data-dissemination/partners')
            || this.$store.state.auth.permissions.includes('/data-dissemination/schedules')
            || this.$store.state.auth.permissions.includes('*');
        },
        showReporting() {
            return this.$store.state.auth.permissions.includes('/reporting/reports')
            || this.$store.state.auth.permissions.includes('*');
        },
        showAudit() {
            return this.$store.state.auth.permissions.includes('/audit/activity-history')
            || this.$store.state.auth.permissions.includes('*');
        },
        showSettings() {
            return this.$store.state.auth.permissions.includes('/settings/alerts')
            || this.$store.state.auth.permissions.includes('/settings/data-model')
             || this.$store.state.auth.permissions.includes('/settings/data-sets')
             || this.$store.state.auth.permissions.includes('/settings/document-management')
             || this.$store.state.auth.permissions.includes('/settings/templates')
             || this.$store.state.auth.permissions.includes('/settings/universes')
             || this.$store.state.auth.permissions.includes('/settings/user-management')
             || this.$store.state.auth.permissions.includes('/settings/workflow')
            || this.$store.state.auth.permissions.includes('*');
        },
        showHelpMenu() {
            return this.$store.state.auth.permissions.includes('/help/data-dictionary')
            || this.$store.state.auth.permissions.includes('/help/support')
            || this.$store.state.auth.permissions.includes('*');
        },
        
        logout() {
            this.$store.dispatch("logout");
        },
        showMenu() {
            if (this.showFullMenu) {
                this.showFullMenu = false;
                this.hideMenuIcon = "angle-double-right";
            } else {
                this.showFullMenu = true;
                this.hideMenuIcon = "angle-double-left";
            }
        },

        showSublist(id) {
            if (this.showFullMenu === true) {
                if (this.menuItems[id].isExpanded === true) {
                    this.menuItems[id].isExpanded = false;
                    this.menuItems[id].sideIcon = "angle-down";
                } else {
                    this.menuItems[id].isExpanded = true;
                    this.menuItems.forEach((item) => {
                        if (item.id !== id) {
                            item.isExpanded = false;
                            item.sideIcon = "angle-down";
                        }
                    });

                    this.menuItems[id].sideIcon = "angle-up";
                }
            }
        },

        navigateBySubItems(
            navigationPath,
            isRouteVisitedfirstTime,
            browserBackButtonClicked = false
        ) {
            if (
                (isRouteVisitedfirstTime === false &&
                    this.$route.path !== navigationPath) ||
                browserBackButtonClicked === true
            ) {
                for (let i = 0; i < this.menuItems.length; i++) {
                    for (
                        let j = 0;
                        j < this.menuItems[i].subItems.length;
                        j++
                    ) {
                        if (
                            this.menuItems[i].subItems[j].navigationPath ===
                            navigationPath
                        ) {
                            // this creates a blue dot highlighter
                            this.menuItems[i].subItems[j].isActive = true;
                            // expands sub-items list group
                            this.menuItems[i].isExpanded = true;
                            // creates a blue border highlighter on left
                            this.menuItems[i].hasActiveSelection = true;

                            // logic to remove the blue dot highlighter from other items
                            let activeSelectionGroup = i;
                            for (
                                let k = 0;
                                k < this.menuItems.length;
                                k++
                            ) {
                                for (
                                    let l = 0;
                                    l < this.menuItems[k].subItems.length;
                                    l++
                                ) {
                                    if (
                                        this.menuItems[k].subItems[l]
                                            .navigationPath !==
                                        navigationPath
                                    ) {
                                        this.menuItems[k].subItems[
                                            l
                                        ].isActive = false;
                                    }
                                }
                            }

                            // removes blue border highlighter on left from other groups
                            for (
                                let m = 0;
                                m < this.menuItems.length;
                                m++
                            ) {
                                if (m !== activeSelectionGroup) {
                                    this.menuItems[
                                        m
                                    ].hasActiveSelection = false;
                                    this.menuItems[m].isExpanded = false;
                                }
                            }
                        }
                    }
                    if (browserBackButtonClicked === true) {
                        if (this.$route.path === navigationPath) {
                            this.$router
                                .push({ path: navigationPath, query: this.$route.query })
                                .catch(() => {});
                        }
                    } else {
                        this.$router.push(navigationPath).catch(() => {});
                    }
                }
            } else {
                // when route is visited for the first time
                for (let i = 0; i < this.menuItems.length; i++) {
                    for (
                        let j = 0;
                        j < this.menuItems[i].subItems.length;
                        j++
                    ) {
                        if (
                            this.menuItems[i].subItems[j].navigationPath ===
                            navigationPath
                        ) {
                            this.menuItems[i].subItems[j].isActive = true;
                            this.menuItems[i].isExpanded = true;
                            this.menuItems[i].hasActiveSelection = true;
                            this.menuItems[i].sideIcon = "angle-up";
                        }
                    }
                }
            }
        },

        // for menu item which don't have any sub items
        navigateByMainItems(navigationPath, id) {

            // open the menu only if the menu item has subitems in it.
            if(!this.showFullMenu && id !== 0) {
                this.showMenu();
            }

            if (this.$route.href !== navigationPath) {
                if (this.menuItems[id].canExpand === false) {
                    this.menuItems[id].hasActiveSelection = true;
                    this.menuItems.forEach((item) => {
                        if (item.id !== id) {
                            item.isExpanded = false;
                            item.sideIcon = "angle-down";
                            item.hasActiveSelection = false;
                        }
                    });
                    for (let i = 0; i < this.menuItems.length; i++) {
                        for (
                            let j = 0;
                            j < this.menuItems[i].subItems.length;
                            j++
                        ) {
                            this.menuItems[i].subItems[j].isActive = false;
                        }
                    }
                    this.$router.push(navigationPath).catch(() => {});
                } else if (this.menuItems[id].canExpand === true) {
                    this.showSublist(id);
                }
            }
        },

         // if user clicks on search icon when menu is closed then, first open the menu & on nextTick focus on the search field.
        onSearchButtonClick() {
            this.showMenu();
            
            // to close all other open menu items.
            for(let i = 0; i < this.menuItems.length; i++) {
                this.menuItems[i].isExpanded = false;
            }

            // to focus on the search field.
            this.$nextTick(() => {
                const element = this.$refs.searchField;
                element.$refs.input.focus();
            });
        },

        // search-panel
        // this event will improve API calling(after 500 ms API will call)
        debounceInput: debounce(function (e) {
            this.searchInput = e.target.value
            this.searchResults();
        }, 500),

        //fetch search-entities and get search-result
        async searchResults() {
            if (this.searchInput.length > 3) {
                let searchEntitiesInput = {
                    showAll: false,
                    showItem: 3,
                    query: this.searchInput,
                };
                this.searchSamples = [];
                let response = await searchEntities(searchEntitiesInput);
                this.getSearchEntities = response.data;
                // await this.fetchSearchEntities(searchEntitiesInput);

                if (this.getSearchEntities.length > 0) {
                    this.searchResult = true;
                    this.searchResultNull = false;
                    let searchedEntities = this.getSearchEntities;
                    searchedEntities.forEach((item) => {
                        this.textHighlight(item.result);
                    });
                    this.searchSamples = searchedEntities;
                    this.searchResultKey += 1;
                } else if (this.getSearchEntities.length == 0) {
                    this.searchResult = true;
                    this.searchSamples = [];
                    this.searchResultNull = true;
                }
            } else {
                this.searchFieldCleared();
            }
        },

        // close search-field and clear searchsamples array
        searchFieldCleared() {
            this.searchResult = false;
            this.searchResultNull = false;
            this.searchSamples = [];
        },

        // route for other screen
        getRouteDetails(url) {
            const routeDetails = this.$router.resolve({
                path: url,
            });
            return routeDetails.resolved.name;
        },

        // route to entity-list with clear search-panel
        clearSearchFiledAndRoute(entityId, searchInput) {
            this.searchResult = false;
            if (this.$route.path != "/data-management/entities") {
                this.$router.push({
                    name: this.getRouteDetails("/data-management/entities"),
                    params: {
                        selectedEntityType: entityId.toString(),
                        searchInput,
                    },
                });
            } else if (this.$route.path == "/data-management/entities") {
                this.passEntityDetailsObject = {
                    selectedEntityType: entityId.toString(),
                    searchInput: searchInput,
                };
            }
        },

        //
        clearSearchFiledAndRouteToAddInvestment(url, id) {
            if (this.$route.path != "/data-management/add-investment") {
                this.$router.push({
                    name: this.getRouteDetails(url),
                    params: {
                        entityid: id,
                    },
                });
            } else if (this.$route.path == "/data-management/add-investment") {
                {
                    this.passAddInvestmentDetailsObject = {
                        selectedEntityType: id,
                    };
                }
            }
        },

        // highlight search-input into search-result
        textHighlight(result) {
            let reg = new RegExp(this.searchInput, "gi");
            for (let i = 0; i < result.length; i++) {
                result[i].name = result[i].name.replace(reg, function (str) {
                    return (
                        "<span style='background-color: #cee7f0'>" +
                        str +
                        "</span>"
                    );
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/styles.scss";

$menu-width: 214px;
$only-icons-menu-width: 60px;

* {
    padding: 0px;
    margin: 0px;
}

.parent {
    display: flex;
    min-width: 1000px;
}

.side-nav {
    width: $menu-width;
    height: 100%;
    background-color: $mds-color-neutral-95;
    position: fixed;
    z-index: 2;
    overflow-x: hidden;
}

.only-icons-nav {
    width: $only-icons-menu-width;
    height: 100%;
    background-color: $mds-color-neutral-95;
    position: fixed;
    z-index: 2;
    overflow-x: hidden;
}

// css for search related functionality
.search-bar {
    height: 50px;
    max-width: 214px;
    border-bottom: 1px solid #cccccc;
    display: flex;
}

.search-icon {
    display: flex;
    align-items: center;
    margin-left: 18px;
}

.input-bar > input {
    display: flex;
    align-items: center;
    height: 50px;
    font-size: 16px;
    border-style: none;
    background-color: inherit;
    width: 70%;
    margin-left: 9.5px;
    color: #5e5e5e;
}

// css for other menu-items except search
// css to add extra space above Home menu-item
.menu-items-list {
    margin-top: 7px;
}

// css to disable the border for each list item
.menu-item {
    color: #1e1e1e;
    border: none;
}

.layout-sub-list {
    margin: 8px 0px;
}

.layout-sub-list:hover {
    color: #006fba;
}

.menu-sub-items-group {
    margin-left: 19px;
}

// css to highlight the selected menu-item & display a border on left
.item-highlight::v-deep {
    border-left: 4px solid #0077cf;
    padding-left: 0px;

    .mds-list-group__link___VueMDS3Demo {
        padding-left: 14px;
        padding-right: 16px;
    }
    .menu-item-text {
        color: #0077cf;
    }
    .menu-sub-item {
        padding-left: 4px;
        margin-left: 10px;
    }
}

.menu-icons {
    margin-top: 5px;
    margin-bottom: 0px;
    height: 23px;
    width: 23px;
}

.menu-left-icon-highlight {
    fill: #0077cf;
    stroke: #0077cf;
}

.menu-item-side-icon {
    position: absolute;
    right: 0px;
    padding-right: 16px;
    margin-top: 11px;
}

.highlight-side-icon {
    fill: #0077cf;
    stroke: #0077cf;
}

.menu-item-text {
    margin-top: 6px;
    margin-left: 10px;
    position: absolute;
}

.menu-sub-item {
    margin-left: 33px;
}

// css to highlight the menu sub item with a dot on left
.sub-item-highlight {
    display: list-item;
    list-style-type: "•";
    color: #006fba;
}

// css to add space to left & right of a list-item
::v-deep .mds-list-group__link___VueMDS3Demo {
    padding-left: 18px;
    padding-right: 16px;
}

// css to remove left-padding from the icons
::v-deep
    .mds-list-group--with-icon___VueMDS3Demo
    .mds-list-group__item-text___VueMDS3Demo {
    padding-left: 0px;
    width: 100%;
}

// css for hide-menu
.hide-menu-item {
    width: 182px;
    height: 50px;
    border-top: 1px solid #cccccc;
    display: flex;
    margin-left: 16px;
    cursor: pointer;
}

.hide-menu-item-with-hidden-menu {
    width: 28px;
    height: 50px;
    border-top: 1px solid #cccccc;
    display: flex;
    margin-left: 16px;
    cursor: pointer;
}

.hide-menu-icon {
    display: flex;
    align-items: center;
    margin-left: 2px;
}

.hide-menu-text {
    display: flex;
    align-items: center;
    height: 50px;
    font-size: 16px;
    border-style: none;
    background-color: inherit;
    width: 70%;
    margin-left: 9.5px;
}

.only-icons {
    display: none;
}

// css for main screen
.main-screen {
    margin-left: $menu-width;
    width: 100%;
    overflow: hidden;
}

.main-screen-with-hidden-menu {
    margin-left: $only-icons-menu-width;
    width: 100%;
    overflow: hidden;
}

.top-bar {
    border-bottom: 1px solid #cccccc;
    background-color: $mds-background-color-white;
    height: 50px;
    position: fixed;
    width: calc(100% - #{$menu-width});
    z-index: 800;
}

.top-bar-with-hidden-menu {
    border-bottom: 1px solid #cccccc;
    background-color: $mds-background-color-white;
    height: 50px;
    position: fixed;
    width: calc(100% - #{$only-icons-menu-width});
    z-index: 800;
}

.top-bar-container {
    display: grid;
    grid-template-columns: auto auto;
}

.brand-logo-container {
    display: flex;
    justify-content: flex-end;
}

.action-icons {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: end;
}

.icon-buttons {
    margin-right: 16px;
}

.signout-listItem ::v-deep {
    padding: 0px;

    .mds-list-group__link___VueMDS3Demo {
        padding: 0px;
        margin: 0px;
    }
}

.content-bar {
    margin-top: 50px;
    width: 100%;
}

// search-panel

.search-result {
    position: fixed;
    height: 500px;
    margin-top: $mds-space-1-x;
    margin-left: 50px;
    padding: 16px;
}

.section-result-header {
    margin-bottom: 16px;
}

.list-group-title {
    text-decoration: none;
    color: #1e1e1e;
}

.search-result-count {
    border-bottom: 1px solid black;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    margin-top: -4px;
    line-height: 18px;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    font-style: normal;
}

.search-result-count:hover {
    color: #006fba;
    border-bottom: 1px solid #006fba;
}

#layout::v-deep .mds-search-results__section___VueMDS3Demo {
    margin-bottom: 24px;
}

#layout::v-deep
    .mds-search-field___VueMDS3Demo.mds-search-field--touch___VueMDS3Demo.mds-search-field--input___VueMDS3Demo
    .mds-search-field__input___VueMDS3Demo {
    background-color: #f2f2f2;
}

#layout::v-deep
    .mds-search-field___VueMDS3Demo.mds-search-field--input___VueMDS3Demo
    .mds-search-field__input___VueMDS3Demo {
    box-shadow: inset 0 -1px 0 0 #f2f2f2;
}

#layout::v-deep
    .mds-search-field___VueMDS3Demo.mds-search-field--touch___VueMDS3Demo.mds-search-field--input___VueMDS3Demo
    .mds-search-field__search-icon___VueMDS3Demo {
    margin-left: 6px;
    margin-top: 1px;
}

#layout::v-deep
    .mds-search-field___VueMDS3Demo.mds-search-field--touch___VueMDS3Demo.mds-search-field--input___VueMDS3Demo
    .mds-search-field__input___VueMDS3Demo {
    padding: 0 40px 0 48px;
    padding-top: 0px;
    padding-right: 40px;
    padding-bottom: 0px;
    padding-left: 48px;
}

.side_menu_sub_heading{
    color: black;
    text-decoration:none;
    display: block;
}
.side_menu_sub_heading-highlight{
    color: #006fba;
    text-decoration:none;
    display: block;
}
</style>
