import axiosCall from '../api/httpClient';
import config from '../config/config.api';

const httpClient = axiosCall(process.env.VUE_APP_BASE_URL);

async function searchEntities(data) {
    return await httpClient.post(config.SEARCH_entities, data).then(async (response) => {
      response["isError"] = false
      return response
    }).catch(error => {
      error.response["isError"] = true  
      return error.response
    })
  }

  export{
    searchEntities,
  }